





































import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Vue from 'vue';
import BaseTeam from '@improve/common-components/src/components/team/BaseTeam.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import Team, { TEAM_TYPE } from '@improve/common-utils/src/model/Team';
import { TeamMemberAction } from '@improve/common-utils/src/types/TeamMemberAction';

@Component({
  name: 'CreateVirtualTeam',
  components: {
    BaseTeam,
    BaseButton
  }
})
export default class CreateVirtualTeam extends Vue {
  @Action createTeam!: ActionMethod;

  @Action processTeamSubscriptions!: ActionMethod;

  private team: Team | null = null;

  get baseTeam(): BaseTeam {
    return this.$refs.baseTeam as any;
  }

  created(): void {
    this.setTeam();
  }

  setTeam(): void {
    this.team = new Team(null, '', '', TEAM_TYPE.VIRTUAL_TEAM);
  }

  async handleSubmit(): Promise<void> {
    if (!(await this.baseTeam.validate())) {
      return;
    }

    const payload: {
      team: Team | null;
      actions: Map<string, TeamMemberAction>;
    } = this.baseTeam.prepareTeam();

    const savedTeam: Team = await this.createTeam(payload.team);
    await this.processTeamSubscriptions({
      teamId: savedTeam.id,
      memberActions: payload.actions
    });
    this.$router.push({ name: 'TeamsTab' });
  }

  goBack(): void {
    this.$router.push({ name: 'TeamsTab' });
  }
}
